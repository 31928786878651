.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*  Top Bardesign*/
#topbarid{
  display: flex;
  justify-content:space-evenly;
  background-color:red;
}
#topbarid #goodstopbarid, #servicetopbarid{
  width: 100px; 
  height: 58px ;
  vertical-align: middle;
 }
 #topbarid h1{color:white; text-align:center;}
nav{
  height: 10vh;
  background-color: #282c34;
  display: flex;
  justify-content:right;
  text-align: center;
  align-items: center;
  color: white;
  padding: 1rem;
  text-decoration: none;
}
.navLink{
  color: white;
  text-decoration: none;
  margin: 0 .7rem 0 0 ;
}

.navLink:hover{
  color: orange;
}
nav .active{
  color: red;
}

.prodbtn{

  margin: 1rem;
  background-color: blue;
  color: white;
  border: 3px solid red;
  border-radius: 12px;
  padding: 0.6rem;
}

section{
  display: grid;
  grid-gap: 1rem;
}

 article{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: bisque;
  border-radius: 18px;
  padding: 0.7rem;
  font-size: 1.2rem;
    
} 
.aboutcontainer{
  background-color: #8cf5ff;
  padding: 1rem;
}
.productcontainer{
  background-color: #8cf5ff;
}
/* Contact page start here*/
#contactContainerDiv{
  display:flex;
  flex-basis: 50%; 
  justify-content:space-between;
}
.bannerH{
  margin-left: 37%;
  text-align:center;
  display: inline-block;  
  background: #7feefc;
  text-decoration: underline;
  font-size: 3rem;
  color: rgb(255, 5, 5);
}
.h3class{
  text-align: center;
  color: rgb(47, 0, 255);
  font-size: 2rem;
  font-weight: bold;
}
#webConnectionId{
  display: flex;
  justify-content: center;  
}
.contactcontainer{
  background-color:  #8cf5ff;
}
.container #webConnectionId .imgConectClas{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffa600;
  margin-right: 0.2rem;
  padding: 0.5rem;
}
#connectDiv address{
  font-size: 1.2rem;
}
#mapDivId{
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* Contact page end here*/
/*swiper slider start here*/
h2,
h3 {
  margin: 0;
}

.swiper {
  width: 660px;
  height: 440px;
}
/* .testimonials {
  padding: 1rem 3rem;
  height: 80vh;
  display: flex;
  flex-direction:column;
  place-items: center;
  background-color: aliceblue;
} */
.testimonials h2 {
    text-align: center;
}

/* .testimonial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: antiquewhite;
} */
.testimonial .testimonial__avatar{
  display: flex;
  align-items: center;
  
  justify-content: center;
}
.testimonial .testimonial__avatar img {
  width: 140px;
  height: 120px;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  margin-right: 0.5rem;
  border-radius: 12%;
}
.testimonial__info {
  text-align: center;
}
.testimonial__desc {
  padding: 0 2rem;
}
/* swiper slider end start here*/
.swiper-slide{
  /* width: 26%;
  margin-left: 35%;
  margin-right: 35%; */
  background-color: #8cf5ff;
} 
.swiper-slide p{
  font-size: 1.2rem;
  font-weight: bold;
}
/* for slide design start here*/
.slideshow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  background-color: beige;
}

.slideshow-elements{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: basis 30%;
}
.slideshow-element {
  text-align: center;
  /* position: absolute; 
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.6s;  */
}
.slideshow-element h2
 {
  font-size: 2.5rem;
  font-weight: bold;
  color: coral;

}
.slideshow-element img {
  width: 25rem;
  height: 20rem;
  
}

.slideshow-element.current {
  /* transform: translate(-50%, -50%) scale(1);
  transition: transform 0.6s;  */
}
/*slide design end here*/



/*for responsive design start here*/
@media screen and (min-width:600px) {
  section{
    grid-template-columns: auto auto;
  }
  
}

@media screen and (min-width:992px) {
  section{
    grid-template-columns: auto auto auto;
  }
  
}
/*Sliding code for geek blog start here */

.sectioncls {
    box-sizing: border-box; 
    display: flex;
    flex-direction: column;
        align-items: center;
    

} 
/* body {
    font-family: Verdana, sans-serif;
} */

.image-sliderfade {
      display: none;
}
.fade {
  justify-content: center;
  align-items: center;
  justify-items: center;
  
}

 .sectioncls .containerdiv .image-sliderfade img{
  display: block; 
  align-items: center; 
  width: 300px;
  height: 340px;
  margin: 12px;
  /* vertical-align: middle; */
} 

/* Slideshow container */
.sectioncls .containerdiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

/* Caption text */
.sectioncls .containerdiv .image-sliderfade .text {
  display: block;
  text-align: center;
  color: #f2f2f2;
  font-size: 2rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 12px;
  background: rgb(255, 6, 6);
  
}

/* The dots/bullets/indicators */
.sectioncls{
  vertical-align: middle;
  width: 83.4%;
  height: auto; 
  margin-left: 115px;
  /* padding: 10px 0px 10px 10px ; */
  border: 2px solid rgb(255, 0, 0);
  box-sizing: border-box;
  background-color: #8cf5ff;
}
.sectioncls .dotdiv{
  display: flex;
  align-items: center;
  background-color: rgb(255, 255, 255);
  
}
.dot {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: transparent;
    border-color: #ddd;
    border-width: 5 px;
    border-style: solid;
    border-radius: 50%;
    display: inline-block;
    transition: border-color 0.6s ease;
}

.active {
    border-color: #666;
}

/* Animation */
.fade {
    -webkit-animation-name: fade-image;
    -webkit-animation-duration: 1.5s;
    animation-name: fade-image;
    animation-duration: 1.5s;
}

@-webkit-keyframes fade-image {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-image {
    from {
        opacity: 0.4;
    }
    to {
        opacity: 1;
    }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px;
    }
}


/*Sliding code for geek blog ends here */
footer{
  width: 104.5%;
  margin-left: 8.5%;
}
footer #footercontent{
  width: 80%;
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  background-color: #0000ff;  
}
footer img{
  width:100px;
  height: 55px;
}
#copyright_txt{
  background-color: #0000ff;
  color: rgb(246, 255, 0);
  font-size: 1rem;
  text-decoration:double;
  font-style: italic;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}